body {
	background: url(https://cdn-images-1.medium.com/max/1600/1*5XH40dQSsBVFtvx3MO5VwQ.png) no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

#myModal {
	margin-top: 4rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}
.modal-header {
	padding: 0.3rem !important;	
}

.modal-body img {
	display: block;
	width: 100%;
	height: 100%;
}

.card {
	width: 400px;
	box-shadow: 3px 3px 20px;
	justify-content: center;
	display: flex;
}
.card:hover {
	transform: scale(1.1);
	z-index: 2;
}

.my-card-list {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	margin-top: 2rem;
	gap: 2rem;
	justify-content: center;
}

.card-image {
	width: inherit;
	height: inherit;
}
