.my-container {
	margin: 0;
	padding: 0;
}

.my-navbar {
	background-image: url(https://cdn-images-1.medium.com/max/1600/1*5XH40dQSsBVFtvx3MO5VwQ.png);
	background-position: center;
	background-size: cover;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	z-index: 9999;
}

.my-brand {
	color: white;
	cursor: pointer;
	text-decoration: none;
	font-family: 'Kaushan Script', cursive;
	font-weight: 800;
	font-size: 2rem !important;
}
.my-brand:hover,
.my-brand:focus {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.my-nav-menu {
	font-size: 1.5rem;
	gap: 1rem;	
}

.my-link.text-white.nav-link {
	text-align: center;
	color: white;	
}

#portfolio-nav-dropdown {
	color: white !important;
	text-align: center !important;
}

.dropdown-menu.show {
	background-color: #294b9d;
}

#portfolio-nav-dropdown.dropdown-toggle.nav-link:hover {
	display: block;
	margin-top: 0;
	color: white;
}
.my-dropdown-item {
	padding-left: 1rem;
	color: white;
}

.my-dropdown-item.dropdown-item:focus,
.my-dropdown-item.dropdown-item:hover {
	background-color: #59aaba;
}

/* Underline From Center */
.my-link,
#portfolio-nav-dropdown {
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.my-link:before,
#portfolio-nav-dropdown:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 51%;
	right: 51%;
	bottom: 0;
	background: #cfeaf7;
	height: 4px;
	-webkit-transition-property: left, right;
	transition-property: left, right;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}
.my-link:hover:before,
.my-link:focus:before,
.my-link:active:before,
#portfolio-nav-dropdown:hover:before,
#portfolio-nav-dropdown:focus:before,
#portfolio-nav-dropdown:active:before {
	left: 0;
	right: 0;
}
