.card-container {
	width: 350px;
	height: 350px;
	position: relative;
	border-radius: 10%;
	overflow: hidden;
	box-shadow: 3px 3px 20px;
	display: flex;
	justify-content: center;
}

.image-container {
	position: relative;
	text-align: center;
}

.image-container img {
	height: 100%;
}

.text-thumb {
	width: 300px;
	position: absolute;
	font-size: 2.3rem;
	bottom: -10%;
	/* top: 50%; */
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	text-shadow: -4px 0 rgb(84, 84, 84), 0 4px rgb(84, 84, 84), 2px 0 rgb(84, 84, 84), 0 -2px rgb(84, 84, 84);
	/* font-style: oblique; */
}

.card-container:hover {
	transform: scale(1.1);
	z-index: 1;
}
