.card {
	margin-top: 2rem;
	align-items: center;
	display: flex;
	direction: row;
	transform: none;
}

@media only screen and (max-width: 480px) {
	.card {
		width: 100vw;
	}
}

@media only screen and (min-width: 900px) {
	.card {
		width: 60vw;
	}
	.container {
		width: 40%;
	}
}

.card-title {
	display: flex;
	justify-content: center;
	padding-top: 1rem;
	font-weight: 800;
}

.card-body {
	display: flex;
	flex-direction: column;
}

.my-links {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}
